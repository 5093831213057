.offerings {
  background-color: #f5f5f5;
  color: #232220; /* Dark Gray text */
  padding: 60px 20px;
  text-align: center;
  width: 100%; /* Ensure it takes full width */
  margin: 0; /* Reset any margins */
  box-sizing: border-box; /* Ensure padding does not exceed width */
}

.offerings .container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center; /* Ensure text alignment is centered */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  padding: 0 20px; /* Add padding to ensure content is not flush against the edges */
}

.offerings h2,
.offerings h3 {
  display: block; /* Ensure both are block-level elements */
  width: 100%; /* Ensure both take full width within their container */
}

.offerings h2 {
  font-size: 2.5rem;
  margin: 0; /* Reset margins */
  padding-bottom: 10px; /* Add padding to create space below */
}

.offerings h3 {
  font-size: 1.5rem;
  margin: 0; /* Reset margins */
  padding-top: 10px; /* Add padding to create space above */
  margin-bottom: 40px;
  color: #AB3E41; /* Red */
}

.offerings-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  box-sizing: border-box; /* Ensure padding and borders are included in total width */
}

.offering {
  background-color: #D8BB9B; /* Beige background */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.offering-image {
  width: 100%;
  height: auto;
}

.offering-content {
  padding: 20px;
}

.offering-content h4 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.offering-content p {
  font-size: 1rem;
  line-height: 1.6;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .offerings {
    padding: 30px 10px; /* Adjust padding for smaller screens */
  }

  .offerings .container {
    padding: 0 10px; /* Reduce side padding on smaller screens */
  }

  .offerings h2 {
    font-size: 2rem; /* Reduce font size for smaller screens */
  }

  .offerings h3 {
    font-size: 1.2rem; /* Reduce font size for smaller screens */
    margin-bottom: 20px; /* Adjust margin for smaller screens */
  }

  .offerings-list {
    grid-template-columns: 1fr; /* Ensure full width columns on smaller screens */
    gap: 20px; /* Reduce gap between items */
  }
}
