/* Footer.css */

body {
  display: flex; /* Enable flexbox layout for the body */
  flex-direction: column; /* Stack children (header, main, footer) vertically */
  min-height: 100vh; /* Ensure body takes at least full viewport height */
  margin: 0; /* Remove default margin */
}

main {
  flex: 1; /* Allow main content to grow and take available space */
}

footer {
  background-color: #232220; /* Dark background color for the footer */
  color: #ffffff; /* White text color */
  padding: 20px 0; /* Padding for the top and bottom */
  text-align: center; /* Center the text for smaller screens */
  width: 100%; /* Ensure it takes full width */
  position: relative; /* Default position, can be static as well */
  bottom: 0; /* Align to bottom */
  margin-top: auto; /* Push the footer to the bottom */
}

.container {
  display: flex; /* Use Flexbox to align items horizontally */
  justify-content: center; /* Center all content horizontally */
  align-items: center; /* Align all items vertically in the center */
  flex-wrap: wrap; /* Allows wrapping to the next line on smaller screens */
  gap: 20px; /* Space between items */
}

.container p {
  margin: 0; /* Remove margin for consistent spacing */
}

.social-links {
  list-style: none; /* Remove bullet points from the list */
  display: flex; /* Use Flexbox for horizontal layout */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.social-links li {
  margin-right: 15px; /* Space between each link */
}

.social-links li a {
  color: #AB3E41; /* Red color for social media links */
  text-decoration: none; /* Remove underline from links */
  transition: color 0.3s; /* Smooth transition for hover effect */
}

.social-links li a:hover {
  color: #AB3E41; /* Red color on hover */
}

.mail {
  color: #AB3E41; /* Red color for email */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
      flex-direction: column; /* Stack items vertically on smaller screens */
      text-align: center; /* Center text on smaller screens */
  }

  .social-links {
      justify-content: center; /* Center social links horizontally */
  }
}
