.about {
    background-color: #232220; /* Dark Gray background */
    color: #D8BB9B; /* Beige text */
    padding: 60px 20px;
    text-align: center;
  }
  
  .about .container {
    max-width: none;
    margin: 0 auto;
  }
  
  .about h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .about p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  