.results {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .results h1, .results h2, .results h3, .results h4 {
    color: #AB3E41;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .results h1 {
    font-size: 2.5em;
    margin-bottom: 5px;
  }
  
  .tournament-date {
    text-align: center;
    font-style: italic;
    margin-bottom: 20px;
  }
  
  .results-subtitle {
    font-size: 2em;
    border-bottom: 2px solid #AB3E41;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .winner-section {
    background-color: #D8BB9B;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .winner-section h3 {
    margin-bottom: 10px;
  }
  
  .winner {
    font-size: 1.8em;
    font-weight: bold;
    color: #AB3E41;
  }
  
  .expandable-section {
    margin-bottom: 30px;
  }
  
  .section-header {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .section-header h3 {
    margin-bottom: 0;
    margin-right: 10px;
  }
  
  .expand-icon {
    font-size: 1.2em;
    transition: transform 0.3s ease;
  }
  
  .expand-icon.expanded {
    transform: rotate(180deg);
  }
  
  .section-content {
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .participants-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    justify-content: center;
  }
  
  .participant {
    background-color: #D8BB9B;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
  }
  
  .match-results .section-header {
    background-color: #D8BB9B;
  }
  
  .match {
    background-color: #f0f0f0;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    padding: 15px;
  }
  
  .match-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 2px;
  }
  
  .match-table th,
  .match-table td {
    padding: 8px;
    text-align: center;
    border-radius: 5px;
  }
  
  .header {
    font-weight: bold;
    background-color: #AB3E41;
    color: white;
  }
  
  .player-name {
    background-color: #D8BB9B;
    font-weight: bold;
    text-align: left;
  }
  
  .score {
    background-color: white;
  }
  
  .final-score {
    font-weight: bold;
    background-color: #D8BB9B;
  }
  
  .youtube-clips {
    margin-top: 30px;
  }
  
  .video-container {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .video-wrapper {
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
  }
  
  .video-wrapper iframe {
    width: 100%;
    height: 315px;
    border-radius: 10px;
  }
  
  .youtube-link {
    font-size: 0.9em;
    color: #AB3E41;
    text-decoration: none;
    margin-top: 10px;
  }
  
  .matches-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  @media (max-width: 768px) {
    .results {
      padding: 10px;
    }
  
    .results h1 {
      font-size: 2em;
    }
  
    .results-subtitle {
      font-size: 1.6em;
    }
  
    .winner {
      font-size: 1.5em;
    }
  
    .participants-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  
    .match-table th,
    .match-table td {
      padding: 6px;
      font-size: 14px;
    }
  
    .video-wrapper iframe {
      height: 56.25vw;
      max-height: 315px;
    }
  
    .match-results {
      width: 100vw;
      margin-left: -10px;
      padding: 0 10px;
    }
  
    .matches-container {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .results h1 {
      font-size: 1.8em;
    }
  
    .results-subtitle {
      font-size: 1.4em;
    }
  
    .winner {
      font-size: 1.3em;
    }
  
    .participants-grid {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
  
    .match-table th,
    .match-table td {
      padding: 4px;
      font-size: 12px;
    }
  
    .player-name {
      max-width: 80px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    .match-results {
      width: 100vw;
      margin-left: -10px;
      padding: 0 5px;
    }
  
    .matches-container {
      width: 100%;
    }
  }