.tournament-page {
    max-width: 1200px;
    width: 90%;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .tournament-page h1 {
    color: #232220;
    font-size: 2.5em;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .tournament-page h2, .tournament-page h3 {
    color: #AB3E41;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .tournament-date {
    text-align: center;
    font-style: italic;
    margin-bottom: 20px;
  }
  
  .tournament-details {
    margin-top: 20px;
  }
  
  .tournament-description {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .entry-info {
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .stream-link, .contact-info, .registration-link {
    margin-bottom: 15px;
  }
  
  .stream-link a, .contact-info a {
    color: #AB3E41;
    text-decoration: none;
  }
  
  .stream-link a:hover, .contact-info a:hover {
    text-decoration: underline;
  }
  
  .register-button {
    display: inline-block;
    background-color: #AB3E41;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .register-button:hover {
    background-color: #8a3234;
  }
  
  @media (max-width: 768px) {
    .tournament-page {
      width: 95%;
      padding: 15px;
      margin: 10px auto;
    }
  
    .tournament-page h1 {
      font-size: 2em;
    }
  
    .tournament-page h2 {
      font-size: 1.5em;
    }
  
    .register-button {
      display: block;
      text-align: center;
    }
  }
  
  @media (max-width: 480px) {
    .tournament-page {
      width: 100%;
      padding: 10px;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
    }
  
    .tournament-page h1 {
      font-size: 1.8em;
    }
  
    .tournament-page h2 {
      font-size: 1.3em;
    }
  }