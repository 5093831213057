.home {
    text-align: center;
    /* margin-top: 50px; */
  }
  
  .hero {
    background: linear-gradient(135deg, #232220, #AB3E41); /* Dark Gray to Red */
    color: #D8BB9B; /* Beige */
    padding: 100px 0;
  }
  
  .hero .container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .hero h1 {
    font-size: 3.5rem;
    margin-bottom: 20px;
    line-height: 1.2;
  }
  
  .hero p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .cta-button {
    background-color: #D8BB9B; /* Beige */
    color: #232220; /* Dark Gray */
    padding: 12px 24px;
    font-size: 1.2rem;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #AB3E41; /* Red */
    color: #FFFFFF; /* White text on red hover */
  }
  
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 2.5rem;
    }
  
    .hero p {
      font-size: 1rem;
    }
  
    .cta-button {
      padding: 10px 20px;
      font-size: 1rem;
    }
  }
  