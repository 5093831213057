.circuit {
    text-align: center;
    margin-top: 0px;
    padding: 20px;
    background: linear-gradient(135deg, #232220, #AB3E41); /* Dark Gray to Red background */
    min-height: 100vh; /* Ensure the background covers the entire viewport height */
    color: #fff; /* Make text white to stand out against the background */
    min-width: 100vw; /* Ensure the background covers the entire viewport width */
  }
  
  .circuit h2 {
    font-size: 2.5rem;
    margin-bottom: 0px;
  }
  
  /* Logo in its own row */
  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px; /* Add spacing below the logo */
  }
  
  .circuit-logo {
    width: 40%;
    max-width: 500px;
    height: auto;
    background-color: #D8BB9B; /* Beige background */
    padding: 10px;
    border-radius: 10px;
  }
  
  /* Tournaments container with static heading */
  .tournaments-container {
    margin: 0 auto;
    width: 80%;
    max-width: 800px;
    /* background-color: rgba(245, 245, 245, 0.8); Semi-transparent background */
    padding: 20px;
    border-radius: 10px;
  }
  
  .tournament-list-wrapper {
    height: 400px; /* Set a fixed height for the scrollable area */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-right: 10px; /* Avoid clipping the scrollbar */
  }
  
  .tournament-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .tournament-item {
    background-color: #D8BB9B; /* Beige */
    border: 1px solid #AB3E41; /* Red */
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    margin-bottom: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .tournament-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }
  
  .tournament-item h3 {
    font-size: 1.5rem;
    color: #232220; /* Dark Gray */
    margin-bottom: 10px;
  }
  
  .tournament-item p {
    color: #232220; /* Dark Gray */
    font-size: 1rem;
  }
  
  /* "See Us in Action" section at the bottom */
  .see-us-in-action {
    margin-top: 50px;
    /* background-color: rgba(245, 245, 245, 0.8); Semi-transparent background */
    padding: 40px 20px;
    border-radius: 10px;
  }
  
  .see-us-in-action h2 {
    color: #ffffff; /* Dark Gray */
    margin-bottom: 20px;
  }
  
  /* Adjust the video size and center it */
  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 900px; /* Set a maximum width */
    margin: 0 auto; /* Center the video */
  }
  
  .video-container iframe {
    width: 100%;
    height: 506px; /* Explicitly set height to match a 16:9 aspect ratio */
    border-radius: 10px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .circuit h2 {
      font-size: 2rem;
    }
  
    .circuit-logo {
      width: 80%;
      max-width: 100%;
    }
  
    .tournaments-container {
      width: 90%;
      max-width: 100%;
    }
  
    .tournament-list-wrapper {
      height: auto; /* Allow height to adjust on smaller screens */
    }
  
    .video-container iframe {
      height: auto; /* Allow the video to resize properly on smaller screens */
    }
  }
  
  .tournament-button {
    padding: 10px 20px;
    min-width: 120px;
    min-height: 20px;
    margin-top: 10px;
    font-size: 1rem;
    color: #fff;
    background-color: #AB3E41; /* Red */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .tournament-button:hover {
    background-color: #932B30; /* Darker shade of red */
  }
  
  .tournament-button.register {
    background-color: #4CAF50; /* Green */
    padding: 10px 20px; /* Ensure padding is consistent */
  }
  
  .tournament-button.register:hover {
    background-color: #3E8E41; /* Darker shade of green */
  }

  .tournament-button.results {
    background-color: #AB3E41; /* red */
    padding: 10px 20px; /* Ensure padding is consistent */
  }

  .tournament-button.results:hover {
    background-color: #932B30; /* darker red */
  }
  