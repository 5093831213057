.navbar {
  background-color: #232220; /* Dark Gray */
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative; /* To contain the dropdown menu */
  z-index: 100; /* Ensures the navbar stays above other elements */
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  position: relative; /* To position dropdown correctly */
}

.navbar .logo img {
  height: 50px; /* Adjust the height to fit your design */
  width: auto;
  max-width: 100%; /* Prevent the logo from exceeding the container size */
}

.navbar ul {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
  align-items: center;
}

.navbar ul li a {
  color: #D8BB9B; /* Beige */
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s;
}

.navbar ul li a:hover {
  color: #AB3E41; /* Red */
}

.menu-toggle {
  display: none; /* Hidden by default */
  font-size: 1.5rem;
  color: #D8BB9B; /* Beige */
  cursor: pointer;
  margin-left: auto; /* Align toggle to the right on smaller screens */
}

/* Responsive styling for mobile devices */
@media (max-width: 768px) {
  .navbar ul {
    flex-direction: column;
    align-items: flex-start;
    display: none; /* Initially hide the navbar items on smaller screens */
    position: absolute;
    top: 60px; /* Adjust based on your layout */
    left: 0;
    right: 0;
    background-color: #232220; /* Same as navbar background */
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .navbar ul.active {
    display: flex; /* Show navbar items when active */
  }

  .navbar .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-toggle {
    display: block; /* Show the menu toggle button on mobile */
  }

  .navbar .logo {
    margin-bottom: 1rem; /* Adds space below the logo on mobile */
  }
}
