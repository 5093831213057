html, body {
  height: 100%; /* Full viewport height for body and html */
  margin: 0; /* No default margin */
  display: flex; /* Enable flexbox for body */
  flex-direction: column; /* Stack children vertically */
}

body {
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  margin: 0;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Ensures this container takes up the full height */
  min-height: 100vh; /* Full viewport height */
}

.main-content {
  flex: 1; /* Allow main content to grow and push footer down */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%; /* Ensure it takes full width */
}


